import React, { useState, useEffect } from 'react';
import axios from 'utils/axios';
import { Box, Card, InputAdornment, OutlinedInput, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconSearch, IconZoomExclamation } from '@tabler/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const SearchContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 8px',
        maxWidth: '100%'
    }
}));

const OutlineInputStyle = styled(OutlinedInput)(({ theme }) => ({
    width: '100%',
    maxWidth: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        maxWidth: 250
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 4,
        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        maxWidth: '100%'
    }
}));

const ResultsDropdown = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    maxHeight: '60vh',
    overflowY: 'auto',
    borderRadius: '8px',
    margin: '5px 14px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
        margin: '5px 0',
        maxHeight: '50vh',
        width: '350px'
    }
}));

const ResultCard = styled(Card)(({ theme }) => ({
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '4px 8px',
    '&:hover': {
        backgroundColor: theme.palette.grey[200]
    },
    [theme.breakpoints.down('sm')]: {
        margin: '4px',
        padding: '8px',
        flexDirection: 'row',
        alignItems: 'flex-start'
    }
}));

const ResultImage = styled(Avatar)({
    width: '40px',
    height: '40px',
    marginRight: '12px'
});

const ResultInfo = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 0, // Prevents flex items from overflowing
    [theme.breakpoints.down('sm')]: {
        gap: '2px'
    }
}));

const ResultTitle = styled(Typography)({
    fontWeight: 500
});

const ResultSubtitle = styled(Typography)({
    fontSize: '0.875rem',
    color: '#666'
});

const ResultType = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 'normal',
    color: theme.palette.primary.main
}));

const SearchSection = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const intl = useIntl();
    const location = useLocation();

    useEffect(() => {
        setSearchTerm('');
    }, [location.pathname]);

    useEffect(() => {
        const fetchData = async () => {
            if (!searchTerm.trim()) {
                setResults([]);
                return;
            }

            setIsLoading(true);

            try {
                const response = await axios.get(`/api/global-search?search=${encodeURIComponent(searchTerm)}`);
                setResults(response.data?.data || []);
            } catch (error) {
                console.error('Error fetching search results:', error);
                setResults([]);
            } finally {
                setIsLoading(false);
            }
        };

        const debounce = setTimeout(fetchData, 300);
        return () => clearTimeout(debounce);
    }, [searchTerm]);

    const formatResult = (item) => {
        let formattedItem = { name: '', description: '', url: '' };

        let customTypeName = '';

        switch (item?.type) {
            case 'Customer':
                customTypeName = 'Potential Customer';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/potential-customers'
                };
                break;
            case 'Admin':
            case 'User':
                customTypeName = 'commanders ';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/principals'
                };
                break;
            case 'Staff':
                customTypeName = 'Astronaut';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/astronauts'
                };
                break;
            case 'spare-part':
                customTypeName = 'Spare Part';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/spareparts'
                };
                break;
            case 'machine':
                customTypeName = 'Machine';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/machines'
                };
                break;

            case 'item':
                customTypeName = 'Carton';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/cartons'
                };
                break;
            case 'quotation':
                customTypeName = 'Quote';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/quotes'
                };
                break;
            case 'contact':
                customTypeName = 'Contact';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/contacts'
                };
                break;
            case 'sample-request':
                customTypeName = 'Sample Request';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/samples'
                };
                break;
            case 'candidate':
                customTypeName = 'Candidate';
                formattedItem = {
                    name: item.name,
                    description: item.description || 'No description',
                    url: '/careers'
                };
                break;
            default:
                console.log('Unknown type:', item.type);
        }

        return { ...formattedItem, customTypeName };
    };

    const handleResultClick = (formattedItem) => {
        navigate(formattedItem.url);
        setSearchTerm('');
        setResults([]);
    };

    return (
        <SearchContainer>
            <OutlineInputStyle
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={intl.formatMessage({ id: 'Search' })}
                startAdornment={
                    <InputAdornment position="start">
                        <IconSearch size="16px" />
                    </InputAdornment>
                }
            />
            {searchTerm.trim() && results.length > 0 && (
                <ResultsDropdown>
                    {results.map((result, index) => {
                        const formattedItem = formatResult(result);
                        return (
                            <ResultCard key={index} onClick={() => handleResultClick(formattedItem)}>
                                <ResultImage src={result.image || '/images/placeholder.webp'} alt={result.name || 'Result'} />
                                <ResultInfo>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <ResultTitle noWrap>
                                            {formattedItem.name?.length > 30
                                                ? `${formattedItem.name.slice(0, 30)}...`
                                                : formattedItem.name || 'N/A'}
                                        </ResultTitle>
                                        <ResultType>
                                            {formattedItem.customTypeName?.length > 30
                                                ? `${formattedItem.customTypeName.slice(0, 30)}...`
                                                : formattedItem.customTypeName || 'N/A'}
                                        </ResultType>
                                    </div>
                                    <ResultSubtitle noWrap>
                                        {result.email?.length > 30 ? `${result.email.slice(0, 30)}...` : result.email || 'N/A'}
                                    </ResultSubtitle>
                                </ResultInfo>
                            </ResultCard>
                        );
                    })}
                </ResultsDropdown>
            )}

            {searchTerm.trim() && results.length === 0 && (
                <ResultsDropdown>
                    <Box display="flex" alignItems="center" justifyContent="center" style={{ padding: '10px' }}>
                        <Typography variant="h5" style={{ marginRight: '8px' }}>
                            <FormattedMessage id="No results found" />
                        </Typography>
                        <IconZoomExclamation stroke={2} />
                    </Box>
                </ResultsDropdown>
            )}

            {isLoading && searchTerm.trim() && <Typography align="center">Loading...</Typography>}
        </SearchContainer>
    );
};

export default SearchSection;
